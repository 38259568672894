import Snackbar from "@mui/material/Snackbar"
import { useState } from "react"

export default function SuccessSnackbar({
  message = "Changes saved",
  open,
  onClose,
  horizontalAnchor = "right",
}) {
  return (
    <Snackbar
      message={message}
      color="primary"
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
      anchorOrigin={{
        horizontal: horizontalAnchor,
        vertical: "bottom",
      }}
    />
  )
}

export function useSnackbarState() {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState("")

  function open(message) {
    setMessage(message)
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
    setMessage("")
  }

  return {
    snackbarOpen: isOpen,
    snackbarMessage: message,
    openSnackbar: open,
    closeSnackbar: close,
  }
}
