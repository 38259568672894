import { createContext, useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useWizard } from "react-use-wizard"

import DefaultAgentAvatar from "../../common/resources/icons/agent-default.jpg"
import {
  axiosClient,
  createStorageKey,
  segmentAnalytics,
  stringCapitalize,
  useAuthorizedAxiosClient,
} from "../../common/utils"
import {
  logoutUser,
  selectIsLoggedOut,
  selectToken,
  selectUserType,
  storeLoginData,
} from "./slice"

export const USER_TYPES = {
  agent: "agent",
  customer: "customer",
}

export const CUSTOMER_HOME_URLS = {
  claimOffice: "/complete-signup/claim-office",
  dashboard: "/brokerage/dashboard",
}

export function usePhoneInputState() {
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState(null)

  return {
    phone,
    setPhone,
    phoneError,
    setPhoneError,
  }
}

export function useValidateSignupData({ defaultEmail = "" }) {
  const [email, setEmail] = useState(defaultEmail)
  const [emailError, setEmailError] = useState(null)
  const { phone, setPhone, phoneError, setPhoneError } = usePhoneInputState()
  const [isValidatingData, setIsValidatingData] = useState(false)

  function handleDataChangedWrapper(valueSetter, errorSetter) {
    return (e) => {
      valueSetter(e.target.value)
      errorSetter(null)
    }
  }

  function validateSignupData() {
    setIsValidatingData(true)
    return axiosClient
      .post("/users/api/signup-data-validator/", { email, phone })
      .catch(({ response: { data } }) => {
        if (data.email) {
          setEmailError(data.email[0])
        }
        if (data.phone) {
          setPhoneError(data.phone[0])
        }
        throw new Error("Signup data invalid")
      })
      .finally(() => setIsValidatingData(false))
  }

  return {
    email,
    setEmail: handleDataChangedWrapper(setEmail, setEmailError),
    emailError,
    phone,
    setPhone: handleDataChangedWrapper(setPhone, setPhoneError),
    phoneError,
    setPhoneError,
    validateSignupData,
    isValidatingData,
  }
}

export function getUserHomeURL(
  userType,
  brokerageCount,
  brokerageHomeOverride
) {
  switch (userType) {
    case USER_TYPES.agent:
      return "/agent/home"
    case USER_TYPES.customer:
      return getBrokerageHomeURL(brokerageCount, brokerageHomeOverride)
    default:
      return "/"
  }
}

export function useUserHomeURL() {
  const userType = useSelector(selectUserType)
  return getUserHomeURL(userType)
}

export function getBrokerageHomeURL(brokerageCount, brokerageHomeOverride) {
  if (brokerageHomeOverride) {
    return brokerageHomeOverride
  }
  if (brokerageCount === 0) {
    return CUSTOMER_HOME_URLS.claimOffice
  }
  return CUSTOMER_HOME_URLS.dashboard
}

export function useLoginUnauthenticatedUser() {
  const token = useSelector(selectToken)
  const isLoggedOut = useSelector(selectIsLoggedOut)
  const navigate = useNavigate()
  const currentURL = useLocation().pathname

  let nextParam = ""

  if (!isLoggedOut) {
    nextParam = `?next=${currentURL}`
  }

  useEffect(
    () => {
      if (!token) {
        navigate(`/users/login${nextParam}`)
      }
    },
    // eslint-disable-next-line
    [token]
  )
}

export function useDefaultAvatar() {
  const userType = useSelector(selectUserType)
  return userType === USER_TYPES.agent ? DefaultAgentAvatar : undefined
}

export function useLogoutUser(logoutURL, onSuccess) {
  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()
  return () =>
    axios.post(logoutURL).then(() => {
      onSuccess()
      segmentAnalytics.track("Logged out")
      segmentAnalytics.reset()
      dispatch(logoutUser())
    })
}

export function getAnonymousName(profile) {
  if (profile.anonymous_name) {
    return profile.anonymous_name
  }
  return `Anonymous agent #${profile.id}`
}

export function useSetUserPassword(apiURL = "/users/api/set-password/") {
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [passwordError, setPasswordError] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  function handlePasswordChanged(e) {
    setPassword(e.target.value)
    setPasswordError(null)
  }

  function handlePassword2Changed(e) {
    setPassword2(e.target.value)
    setPasswordError(null)
  }

  function setPasswordErrorFromData(data) {
    let passwordError = data.password_1
    if (data.password_1 instanceof Array) {
      passwordError = data.password_1.join(" ")
    }
    setPasswordError(passwordError)
  }

  function setUserPassword(key) {
    setIsSubmitting(true)

    return axiosClient
      .post(apiURL, {
        password_1: password,
        password_2: password2,
        key,
      })
      .catch(({ response: { data } }) => {
        if (data.password_1) {
          setPasswordErrorFromData(data)
        } else if (data.key) {
          setPasswordError(data.key)
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return {
    password,
    setPassword: handlePasswordChanged,
    password2,
    setPassword2: handlePassword2Changed,
    passwordError,
    setPasswordError,
    setPasswordErrorFromData,
    setUserPassword,
    isSubmitting,
  }
}

export const BROKERAGE_PHONE_STORAGE_KEY = createStorageKey("phone")

export function mixpanelUserType(userType) {
  return userType === USER_TYPES.agent ? "agent" : "broker"
}

export function useUserTypeAndSearchParams() {
  const pathname = useLocation().pathname
  const searchParams = useSearchParams()[0]

  const userType = pathname.split("/")[3]

  const brokerageSearchParams = new URLSearchParams()
  const agentRequestID = searchParams.get("arid")
  if (agentRequestID) {
    brokerageSearchParams.append("arid", agentRequestID)
  }
  return { userType, brokerageSearchParams }
}

export function useGetAgentRequestData() {
  const agentRequestID =
    useUserTypeAndSearchParams().brokerageSearchParams.get("arid")
  const [agentRequestData, setAgentRequestData] = useState({
    final_name: "Ryan Brown",
    agent_identity_revealed: false,
    agent: { level_of_interest: "active" },
    sales_volume_last_year: 1.6e6,
    avg_sales_volume_last_year: 5e5,
    years_in_business: 7,
    avg_years_in_business: 10,
  })
  const [isLoadingAgentRequestData, setIsLoadingAgentRequestData] =
    useState(false)

  useEffect(() => {
    if (!agentRequestID) {
      return
    }
    setIsLoadingAgentRequestData(true)
    axiosClient
      .get(`/customers/api/signup-agent-request-info/${agentRequestID}/`)
      .then(({ data }) =>
        setAgentRequestData({
          ...data,
          final_name: data.agent_identity_revealed
            ? data.final_name
            : "Ryan Brown",
        })
      )
      .finally(() => setIsLoadingAgentRequestData(false))
  }, [agentRequestID])

  return { agentRequestData, isLoadingAgentRequestData }
}

export function useSignupCampaign() {
  const searchParams = useSearchParams()[0]
  const signupCampaign = searchParams.get("campaign")
  const storageKey = createStorageKey("signup-campaign")

  function getSignupCampaign() {
    return signupCampaign || localStorage.getItem(storageKey)
  }

  function clearSignupCampaign() {
    localStorage.removeItem(storageKey)
  }

  // store signup campaign if they drop off during signup
  useEffect(
    () => {
      if (signupCampaign) {
        localStorage.setItem(storageKey, signupCampaign)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signupCampaign]
  )

  return {
    getSignupCampaign,
    clearSignupCampaign,
  }
}

export function useHandleLoginSuccess() {
  const dispatch = useDispatch()

  return (data) => {
    dispatch(storeLoginData(data))
    mixpanelIdentifyUser(data)
  }
}

export function useHandlePasswordSet() {
  const dispatch = useDispatch()

  return (data, onSuccess) => {
    dispatch(storeLoginData(data))
    segmentAnalytics.track("Password set")
    mixpanelIdentifyUser(data)
    onSuccess(data)
  }
}

export function useSignupTrackingUserType() {
  const urlUserType = useLocation().pathname.split("/")[3]
  return urlUserType === "agent" ? "agent" : "broker"
}

export function useTrackSignupStep(stepIndex, stepTitle) {
  const userType = useSignupTrackingUserType()

  useEffect(
    () => {
      trackSignupStep(userType, stepIndex, stepTitle)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export function trackSignupStep(userType, stepIndex, stepTitle) {
  segmentAnalytics.track(`${stringCapitalize(userType)} signup step visited`, {
    "Step index": stepIndex + 1,
    "Step title": stepTitle,
  })
}

export function useIsSigningUp() {
  const location = useLocation()
  return location.pathname.startsWith("/users/signup/")
}

export function mixpanelIdentifyUser(user) {
  segmentAnalytics.identify(user.email)
  segmentAnalytics.track("Logged in")
}

export function mixpanelTrackHandleConfirmed() {
  segmentAnalytics.track("Phone or email confirmed")
}

export function mixpanelTrackSignupWizardFinished(userType) {
  segmentAnalytics.track(`${stringCapitalize(userType)} signup wizard finished`)
}

export const UnfinishedDataContext = createContext()

export function useTrackUserQuitUnfinishedSignup() {
  const [isTrackingQuitUnfinished, setIsTrackingQuitUnfinished] =
    useState(false)
  const { userType } = useUserTypeAndSearchParams()

  function trackQuitUnfinshedSignup(unfinishedData) {
    setIsTrackingQuitUnfinished(true)
    segmentAnalytics.track(
      `${stringCapitalize(userType)} quit signup wizard`,
      unfinishedData
    )
    return axiosClient
      .post("/users/api/track-unfinished-signup/", {
        user_type: userType,
        data: unfinishedData,
      })
      .finally(() => setIsTrackingQuitUnfinished(false))
  }

  return {
    isTrackingQuitUnfinished,
    trackQuitUnfinshedSignup,
  }
}

export function useTrackBrowserClosed(unfinishedData) {
  const { trackQuitUnfinshedSignup } = useTrackUserQuitUnfinishedSignup()

  useEffect(
    () => {
      function handleBeforeUnload(e) {
        if (unfinishedData.email) {
          e.returnValue = true
          trackQuitUnfinshedSignup(unfinishedData)
        }
      }
      window.onbeforeunload = handleBeforeUnload
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export const StepPlanContext = createContext([])

export function useStepNavigator() {
  const stepIndexes = useContext(StepPlanContext)
  const { goToStep, activeStep } = useWizard()
  const activeStepIndex = stepIndexes.indexOf(activeStep)

  function nextStep() {
    goToStep(stepIndexes[activeStepIndex + 1])
  }

  function previousStep() {
    goToStep(stepIndexes[activeStepIndex - 1])
  }

  function goForwards(stepCount) {
    goToStep(stepIndexes[activeStepIndex + stepCount])
  }

  function goBackwards(stepCount) {
    goToStep(stepIndexes[activeStepIndex - stepCount])
  }

  return {
    nextStep,
    previousStep,
    activeStep: activeStepIndex,
    goToStep,
    goForwards,
    goBackwards,
  }
}

export function useSendPhoneVerificationSMS() {
  const [isSendingPhoneVerification, setIsSendingPhoneVerification] =
    useState(false)

  function sendVerification(phone, setPhoneError) {
    setIsSendingPhoneVerification(true)
    return axiosClient
      .post("/users/api/signup/send-phone-code/", { phone })
      .catch(({ response: { data } }) => {
        if (data.phone) {
          setPhoneError(data.phone[0])
        }
        throw new Error("Failed to send phone code")
      })
      .finally(() => setIsSendingPhoneVerification(false))
  }
  return {
    isSendingPhoneVerification,
    sendVerification,
  }
}

export function useVerifyPhoneCode(
  apiURL = "/users/api/signup/verify-phone-code/",
  authenticate = false
) {
  const [isVerifying, setIsVerifying] = useState(false)
  const authorizedAxios = useAuthorizedAxiosClient()
  const axios = authenticate ? authorizedAxios : axiosClient

  function verifyPhoneCode(phone, digitString, setDigitCodeError) {
    return axios
      .post(apiURL, {
        phone,
        code: digitString,
      })
      .catch(({ response: { data } }) => {
        if (data.code) {
          setDigitCodeError("Invalid confirmation code")
        } else if (data.phone) {
          setDigitCodeError(data.phone)
        }
        return Promise.reject("Phone verification failed")
      })
      .finally(() => setIsVerifying(false))
  }
  return {
    isVerifying,
    verifyPhoneCode,
  }
}

export function useValidatePassowrds() {
  const [isValidating, setIsValidating] = useState(false)

  function validatePasswords(password, password2, setPasswordErrorFromData) {
    setIsValidating(true)
    return axiosClient
      .post("/users/api/signup/validate-passwords/", {
        password_1: password,
        password_2: password2,
      })
      .catch(({ response: { data } }) => {
        setPasswordErrorFromData(data)
        return Promise.reject("Invalid passwords")
      })
      .finally(() => setIsValidating(false))
  }

  return {
    isValidating,
    validatePasswords,
  }
}

export function agentCanEnterSigningBonus(
  yearsInBusiness,
  salesVolumeLastYear
) {
  return yearsInBusiness >= 2 && salesVolumeLastYear >= 860e3
}
