import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"

import {
  GrayBorderedChip,
  PurpleBorderedChip,
} from "../../../common/components/BorderedChips"
import Column from "../../../common/components/Column"
import { RowButColumnOnMobile } from "../../../common/components/Row"
import { SettingsSectionContainer } from "../../../common/components/SettingsSection"
import SuccessSnackbar, {
  useSnackbarState,
} from "../../../common/components/SuccessSnackbar"
import {
  formatCurrency,
  trimLongString,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import {
  NoResultsTableRow,
  SortableHeaderTableCell,
  useTableSort,
} from "../components/Tables"
import { formatDate } from "../utils"
import AddOrUpdateAutoMessageDialog, {
  ACTION_BUTTON_MODES,
} from "./AddOrUpdateAutoMessageDialog"

export default function AutoMessagingTab() {
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [whatIsThisDialogOpen, setWhatIsThisDialogOpen] = useState(false)
  const [addAutoMessageDialogOpen, setAddAutoMessageDialogOpen] =
    useState(false)
  const [clickedAutoMessage, setClickedAutoMessage] = useState(null)
  const { sortTableBy, sortDirection, sortByColumnWrapper } = useTableSort()
  const { snackbarOpen, openSnackbar, snackbarMessage, closeSnackbar } =
    useSnackbarState()
  const axios = useAuthorizedAxiosClient()
  const filteredMessages = messages.filter(
    (message) =>
      message.title.toLowerCase().includes(searchText) +
      message.text.toLowerCase().includes(searchText)
  )
  const sortedFilteredMessages = filteredMessages
    .slice()
    .sort((message1, message2) => {
      let comparable1 = message1[sortTableBy]
      let comparable2 = message2[sortTableBy]
      if (sortTableBy === "created") {
        comparable1 = new Date(comparable1)
        comparable2 = new Date(comparable2)
      }
      let result
      if (
        [
          "send_count",
          "sales_volume_last_year",
          "homes_sold_last_year",
          "years_in_business",
          "signing_bonus",
          "created",
        ].indexOf(sortTableBy) > -1
      ) {
        result =
          comparable1 < comparable2 ? -1 : comparable2 < comparable1 ? 1 : 0
      } else {
        result = comparable1.localeCompare(comparable2)
      }
      return sortDirection === "asc" ? result : -result
    })

  function handleAutoMessageRowClicked(autoMessage) {
    setAddAutoMessageDialogOpen(true)
    setClickedAutoMessage(autoMessage)
  }

  function handleAddOrUpdateAutoMessageDialogClosed(
    autoMessage,
    actionButtonMode
  ) {
    setAddAutoMessageDialogOpen(false)
    if (actionButtonMode) {
      if (actionButtonMode === ACTION_BUTTON_MODES.update) {
        setMessages(
          messages.map((message) =>
            message.id === autoMessage.id ? autoMessage : message
          )
        )
        openSnackbar("Auto message updated successfully")
      } else {
        setMessages(
          messages.filter((message) => message.id !== clickedAutoMessage.id)
        )
        openSnackbar("Auto message deleted successfully")
      }
    } else if (autoMessage) {
      setMessages([...messages, autoMessage])
      openSnackbar("Auto message created successfully")
    }
    setClickedAutoMessage(null)
  }

  useEffect(
    () => {
      setIsLoading(true)
      axios
        .get("/customers/api/text-message-template/")
        .then(({ data }) => setMessages(data))
        .finally(() => setIsLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <SettingsSectionContainer>
      <Column sx={{ gap: 2 }}>
        <RowButColumnOnMobile
          sx={{
            justifyContent: { xs: "flex-start", sm: "space-between" },
            alignItems: { xs: "stretch", sm: "center" },
            gap: 2,
          }}
        >
          <Button
            variant="text"
            color="inherit"
            size="small"
            startIcon={<InfoOutlinedIcon />}
            onClick={() => setWhatIsThisDialogOpen(true)}
          >
            What is this?
          </Button>
          <RowButColumnOnMobile sx={{ gap: 2 }}>
            <TextField
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
              sx={{ width: { xs: "100%", sm: "220px" } }}
            />
            <LoadingButton
              color="primary"
              variant="contained"
              size="small"
              loading={isLoading}
              startIcon={<AddOutlinedIcon />}
              onClick={() => setAddAutoMessageDialogOpen(true)}
            >
              Add Auto Message
            </LoadingButton>
          </RowButColumnOnMobile>
        </RowButColumnOnMobile>
        <TableContainer sx={{ maxWidth: { xs: "calc(100vw - 64px)" } }}>
          <Table>
            <TableHead>
              <TableRow>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="title"
                >
                  Title
                </SortableHeaderTableCell>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="text"
                >
                  Text
                </SortableHeaderTableCell>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="status"
                >
                  Status
                </SortableHeaderTableCell>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="send_count"
                >
                  Times sent
                </SortableHeaderTableCell>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="years_in_business"
                >
                  Years in business
                </SortableHeaderTableCell>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="sales_volume_last_year"
                >
                  Sales volume ($)
                </SortableHeaderTableCell>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="homes_sold_last_year"
                >
                  Homes sold
                </SortableHeaderTableCell>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="signing_bonus"
                >
                  Signing bonus ($)
                </SortableHeaderTableCell>
                <SortableHeaderTableCell
                  sortTableBy={sortTableBy}
                  sortDirection={sortDirection}
                  sortByColumnWrapper={sortByColumnWrapper}
                  columnName="created"
                >
                  Created
                </SortableHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedFilteredMessages.length === 0 ? (
                <NoResultsTableRow columnCount={9}>
                  No messages{" "}
                  {messages.length === 0 ? "created yet" : "match your query"}.
                </NoResultsTableRow>
              ) : (
                sortedFilteredMessages.map((message) => (
                  <TableRow
                    key={message.id}
                    onClick={() => handleAutoMessageRowClicked(message)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{message.title}</TableCell>
                    <TableCell>{trimLongString(message.text, 18)}</TableCell>
                    <TableCell>
                      {message.status === "draft" ? (
                        <GrayBorderedChip label="Draft" />
                      ) : (
                        <PurpleBorderedChip label="Live" />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {message.send_count.toLocaleString()}
                    </TableCell>
                    <TableCell align="right">
                      {message.years_in_business}
                    </TableCell>
                    <TableCell align="right">
                      {message.sales_volume_last_year &&
                        formatCurrency(message.sales_volume_last_year)}
                    </TableCell>
                    <TableCell align="right">
                      {message.homes_sold_last_year}
                    </TableCell>
                    <TableCell align="right">
                      {message.signing_bonus &&
                        formatCurrency(message.signing_bonus)}
                    </TableCell>
                    <TableCell>{formatDate(message.created)}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Column>
      <WhatIsThisDialog
        open={whatIsThisDialogOpen}
        onClose={() => setWhatIsThisDialogOpen(false)}
      />
      <AddOrUpdateAutoMessageDialog
        open={addAutoMessageDialogOpen}
        onClose={handleAddOrUpdateAutoMessageDialogClosed}
        existingAutoMessage={clickedAutoMessage}
      />
      <SuccessSnackbar
        open={snackbarOpen}
        onClose={closeSnackbar}
        message={snackbarMessage}
      />
    </SettingsSectionContainer>
  )
}

function WhatIsThisDialog({ open, onClose }) {
  const isMobile = useIsMobile()
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={!isMobile}>
      <DialogContent>
        <Column sx={{ gap: 1 }}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Auto Messaging
          </Typography>
          <Typography variant="body1" color="text.secondary2" sx={{ mt: 2 }}>
            Engage new agents by having a chat message go out to them
            automatically when they sign up. Chat messages go out only if the
            agent matches or exceeds conditions you defined for the message. You
            can define conditions for the following agent criteria:
          </Typography>
          <ul style={{ fontWeight: 600, marginBlock: 0 }}>
            <li>Years in business</li>
            <li>Sales volume</li>
            <li>Homes sold</li>
            <li>Signing bonus</li>
          </ul>
          <Typography variant="body1" color="text.secondary2">
            You don't have to set values for all the criteria when creating your
            message. If you don't define a criteria it'll match all agents.
          </Typography>
          <Typography variant="body1" color="text.secondary2">
            And finally, if an agent matches multiple messages, only one of them
            will be sent out to them.
          </Typography>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
