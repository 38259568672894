import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import Column from "./Column"

export default function LengthLimitedTextField({
  label,
  placeholder,
  value,
  setValue,
  maxLength,
  multiline,
  required,
  helperText,
}) {
  function handleValueChanged(e) {
    const newValue = e.target.value
    if (newValue.length <= maxLength) {
      setValue(newValue)
    }
  }
  return (
    <Column sx={{ gap: 1 }}>
      <TextField
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={handleValueChanged}
        fullWidth
        multiline={multiline}
        rows={3}
        required={required}
        error={!!helperText}
        helperText={helperText}
      />
      <Typography
        variant="body2"
        color="text.secondary2"
        sx={{ textAlign: "right" }}
      >
        {value.length}/{maxLength}
      </Typography>
    </Column>
  )
}
