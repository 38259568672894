import { alpha, createTheme } from "@mui/material/styles"

import ErrorCircleIcon from "./common/resources/icons/error-circle.svg"

const SECONDARY_COLOR = "#DC3495"

export default createTheme({
  typography: {
    fontFamily: ["Proxima Nova", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#7754c2",
      light: "#E8E3F7",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    error: {
      main: "#B13B37",
    },
    text: {
      primary: "#383443",
      secondary: "#807E88",
      secondary2: "#757380",
    },
    otherwise: {
      border: "#E7E8F1",
      lightBackground: "#F5F6FA",
      darkBackground: "#e8eaf2",
      mapPoint: "#614bab",
      mapPointOutline: "#aea1d5",
      gradientBackground:
        "linear-gradient(86.6deg, #793BD9 -22.51%, #5D4DA4 101.37%)",
      secondaryWhite: "#a797d4",
      highlightShadow: `0px 0px 4px 4px ${alpha(SECONDARY_COLOR, 0.25)}`,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&::selection": {
            background: alpha(theme.palette.primary.light, 0.5),
          },
        }),
        h4: {
          fontWeight: 600,
        },
        h5: {
          fontSize: "1.75rem",
          fontWeight: 600,
        },
        h6: {
          fontWeight: 600,
        },
        subtitle1: ({ theme }) => ({
          color: theme.palette.text.secondary2,
        }),
        subtitle2: ({ theme }) => ({
          color: theme.palette.text.secondary2,
        }),
        caption: {
          fontSize: "0.9em",
          fontWeight: 500,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "8px",
        }),
        colorPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.otherwise.lightBackground,
        }),
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge": {
            padding: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          padding: "8px 20px",
          textTransform: "initial",
          whiteSpace: "nowrap",
        },
        contained: ({ theme }) => ({
          backgroundColor: theme.palette.otherwise.gradientBackground,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: "white",
          color: `${theme.palette.text.secondary2}!important`,
          paddingLeft: 4,
          paddingRight: 4,
          lineHeight: "1.8rem",
          "&.Mui-filled": {
            top: "-2px",
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: "8px",
          "&.Mui-error::before": {
            content: `url(${ErrorCircleIcon})`,
            marginRight: "6px",
            verticalAlign: "text-top",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "12px",
          "&:hover:not(.Mui-error) fieldset": {
            borderColor: `${theme.palette.primary.main}!important`,
          },
          "& fieldset": {
            border: "1px solid #eff0f6",
          },
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        variant: "caption",
        underline: "hover",
        color: "text.secondary",
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: ({ theme }) => ({
          borderRadius: theme.units.borderRadius,
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.otherwise.border,
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.units.borderRadius,
        }),
        elevation0: ({ theme }) => ({
          padding: theme.spacing(2),
          border: `1px solid ${theme.palette.otherwise.border}`,
        }),
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiSnackbarContent-root": {
            background: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTab-root:nth-of-type(n + 2)": {
            marginLeft: "32px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1rem",
          "&.Mui-selected": {
            color: "inherit",
          },
          padding: "12px 0px",
          minWidth: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiPaper-root": {
            padding: theme.spacing(4),
            [theme.breakpoints.only("xs")]: {
              padding: theme.spacing(2),
            },
          },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: "flex",
          padding: 0,
          paddingTop: theme.spacing(4),
          "& .MuiButton-root, & .MuiButtonGroup-root": {
            flexBasis: "50%",
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiPaper-root": {
            borderRadius: theme.units.borderRadius,
            padding: `0 ${theme.spacing(1)}`,
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "6px",
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          width: "4em",
          textTransform: "none",
          border: 0,
          borderRadius: "10px!important",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${theme.palette.otherwise.border}`,
          borderRadius: theme.units.borderRadius,
          "& .MuiToggleButtonGroup-grouped": {
            margin: theme.spacing(0.5),
            "&.Mui-disabled": {
              border: 0,
            },
            "&:not(:first-of-type)": {
              borderRadius: theme.shape.borderRadius,
            },
            "&:first-of-type": {
              borderRadius: theme.shape.borderRadius,
            },
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          "&.MuiCollapse-hidden": { width: "0px" },
        },
      },
    },
  },
  units: {
    borderRadius: "12px",
    textBlur: "blur(3.5px)",
  },
})
