import GolfCourseOutlinedIcon from "@mui/icons-material/GolfCourseOutlined"
import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import LengthLimitedTextField from "../../../common/components/LengthLimitedTextField"
import LoadingButton from "../../../common/components/LoadingButton"
import { useAuthorizedAxiosClient, useIsMobile } from "../../../common/utils"
import { profileLoading, selectProfile, updateProfile } from "../slice"

export default function SelectSignupReasonDialog() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [signupReason, setSignupReason] = useState("")
  const [customSignupReason, setCustomSignupReason] = useState("")
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false)
  const axios = useAuthorizedAxiosClient()
  const profile = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const SOMETHING_ELSE_OPTION = "Something else"
  const selectedSomethingElseOption = signupReason === SOMETHING_ELSE_OPTION

  const SIGNUP_REASONS = [
    "Improve commission split",
    "Seeking a signing bonus",
    "More training and mentorship",
    "Office culture and location",
    "Leads provided by company",
    "Admin or marketing support",
    SOMETHING_ELSE_OPTION,
  ]

  function handleSignupReasonChanged(e) {
    setSignupReason(e.target.value)
    setCustomSignupReason("")
  }

  function handleSubmitButtonClicked() {
    setIsUpdatingProfile(true)
    axios
      .put("/agents/api/profile/", {
        signup_reason: customSignupReason || signupReason,
      })
      .then(({ data }) => {
        dispatch(updateProfile(data))
        setIsDialogOpen(false)
      })
      .finally(() => setIsUpdatingProfile(true))
  }

  useEffect(() => {
    if (isProfileLoading) {
      return
    }
    if (profile.signup_reason === null) {
      setIsDialogOpen(true)
    }
  }, [isProfileLoading, profile])

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column>
          <Column sx={{ alignItems: "center", textAlign: "center" }}>
            <HaloCircularIcon size={64} Icon={GolfCourseOutlinedIcon} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              What's most important to you right now?
            </Typography>
          </Column>
          <RadioGroup
            value={signupReason}
            onChange={handleSignupReasonChanged}
            sx={{ mt: 2, pl: 1.5 }}
          >
            {SIGNUP_REASONS.map((signupReason, i) => (
              <FormControlLabel
                value={signupReason}
                control={<Radio size="small" />}
                label={signupReason}
                key={i}
              />
            ))}
          </RadioGroup>
          <Collapse in={selectedSomethingElseOption}>
            <Column sx={{ mt: 2 }}>
              <LengthLimitedTextField
                label="Thing most important to you"
                maxLength={64}
                multiline
                value={customSignupReason}
                setValue={setCustomSignupReason}
              />
            </Column>
          </Collapse>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsDialogOpen(false)}
        >
          Cancel
        </Button>
        <LoadingButton
          isLoading={isUpdatingProfile}
          disabled={selectedSomethingElseOption && customSignupReason === ""}
          onClick={handleSubmitButtonClicked}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
