import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import Column from "../../../common/components/Column"
import LeftAlignedDialogActions from "../../../common/components/LeftAlignedDialogActions"
import LengthLimitedTextField from "../../../common/components/LengthLimitedTextField"
import {
  handleChangedPositiveOnlyTextField,
  segmentAnalytics,
  useAuthorizedAxiosClient,
  useIsMobile,
} from "../../../common/utils"
import PerksEditor, { useSelectedPerks } from "../components/PerksEditor"
import { profileLoading, selectCurrentBrokerage } from "../slice"
import SaveDraftProposalDialog from "./SaveDraftProposalDialog"
import SendProposalDialog from "./SendProposalDialog"

export default function CreateProposalDialog({
  open,
  onClose,
  proposalToDuplicate,
  conversation,
}) {
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(null)
  const [introduction, setIntroduction] = useState("")
  const [introductionError, setIntroductionError] = useState(null)
  const [signingBonus, setSigningBonus] = useState(0)
  const [signingBonusError, setSigningBonusError] = useState(null)
  const [brokerageCommissionRatio, setBrokerageCommissionRatio] = useState(0)
  const [brokerageCommissionRatioError, setBrokerageCommissionRatioError] =
    useState(null)
  const [perTransactionFee, setPerTransactionFee] = useState(0)
  const [monthlyDeskFee, setMonthlyDeskFee] = useState(0)
  const { selectedPerks, setSelectedPerks } = useSelectedPerks([])
  const [note, setNote] = useState("")
  const [isSaveDraftDialogOpen, setIsSaveDraftDialogOpen] = useState(false)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [isSendProposalDialogOpen, setIsSendProposalDialogOpen] =
    useState(false)
  const [createdProposalID, setCreatedProposalID] = useState(null)

  const isProfileLoading = useSelector(profileLoading)
  const currentBrokerage = useSelector(selectCurrentBrokerage)
  const axios = useAuthorizedAxiosClient()
  const isMobile = useIsMobile()

  const FIELD_REQUIRED_ERROR = "This field is required"

  function handleNameChanged(newValue) {
    setName(newValue)
    if (newValue && nameError) {
      setNameError(null)
    }
  }

  function handleIntroductionChanged(newValue) {
    setIntroduction(newValue)
    if (newValue && introductionError) {
      setIntroductionError(null)
    }
  }

  function handleSigningBonusChanged(e) {
    handleChangedPositiveOnlyTextField(e, (newValue) => {
      setSigningBonus(newValue)
      if (newValue && signingBonusError) {
        setSigningBonusError(null)
      }
    })
  }

  function handleCommissionRatioChanged(e) {
    handleChangedPositiveOnlyTextField(e, (newValue) => {
      const minValue = 0
      const maxValue = 90
      if (newValue && (newValue < minValue || newValue > maxValue)) {
        setBrokerageCommissionRatioError(`Not from ${minValue} to ${maxValue}`)
      } else {
        setBrokerageCommissionRatioError(null)
      }
      setBrokerageCommissionRatio(newValue)
    })
  }

  function handleSendProposalButtonClicked() {
    const badSigningBonus = signingBonus === ""
    const badCommissionRatio = brokerageCommissionRatio === ""
    if (!name || !introduction || badSigningBonus || badCommissionRatio) {
      if (!name) {
        setNameError(FIELD_REQUIRED_ERROR)
      }
      if (!introduction) {
        setIntroductionError(FIELD_REQUIRED_ERROR)
      }
      if (badSigningBonus) {
        setSigningBonusError(FIELD_REQUIRED_ERROR)
      }
      if (badCommissionRatio) {
        setBrokerageCommissionRatioError(FIELD_REQUIRED_ERROR)
      }
    } else {
      setIsSendProposalDialogOpen(true)
    }
  }

  function handleSendProposalToCandidateButtonClicked() {
    saveProposal(false).then(({ id }) => setCreatedProposalID(id))
  }

  function handleSendingProposalFinished(data) {
    setCreatedProposalID(null)
    setIsSendProposalDialogOpen(false)
    onClose(data, false)
  }

  function handleCancelButtonClicked() {
    setIsSaveDraftDialogOpen(true)
  }

  function handleSaveDraftConfirmed() {
    saveProposal(true).then((data) => onClose(data, true))
  }

  function handleSaveDraftCancelled() {
    setIsSaveDraftDialogOpen(false)
    onClose()
  }

  function saveProposal(isDraft) {
    setIsSavingDraft(true)
    return axios
      .post(`/customers/api/${currentBrokerage.id}/proposals/`, {
        name,
        introduction,
        signing_bonus: signingBonus,
        brokerage_commission_ratio: brokerageCommissionRatio,
        per_transaction_fee: perTransactionFee,
        monthly_desk_fee: monthlyDeskFee,
        perks: selectedPerks.map(({ id }) => id),
        note,
        is_draft: isDraft,
      })
      .then(({ data }) => {
        setIsSaveDraftDialogOpen(false)
        setIntroduction("")
        setSigningBonus(0)
        setBrokerageCommissionRatio(0)
        setPerTransactionFee(0)
        setMonthlyDeskFee(0)
        setSelectedPerks([])
        setNote("")
        if (isDraft) {
          segmentAnalytics.track("Broker created proposal draft", {
            "Brokerage ID": currentBrokerage.id,
            "Brokerage name": currentBrokerage.company,
            "Proposal ID": data.id,
            "Proposal name": data.name,
          })
        }
        return data
      })
      .finally(() => setIsSavingDraft(false))
  }

  function getInitialFieldValue(fieldName, default_) {
    return proposalToDuplicate ? proposalToDuplicate[fieldName] : default_
  }

  useEffect(
    () => {
      if (isProfileLoading) {
        return
      }
      setName(
        getInitialFieldValue("name", `${currentBrokerage.company} Proposal 1`)
      )
      setIntroduction(getInitialFieldValue("introduction", ""))
      setSigningBonus(getInitialFieldValue("signing_bonus", 0))
      setBrokerageCommissionRatio(
        getInitialFieldValue("brokerage_commission_ratio", 0)
      )
      setPerTransactionFee(getInitialFieldValue("per_transaction_fee", 0))
      setMonthlyDeskFee(getInitialFieldValue("monthly_desk_fee", 0))
      setSelectedPerks(getInitialFieldValue("perks", []))
      setNote(getInitialFieldValue("note", ""))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isProfileLoading, currentBrokerage, proposalToDuplicate]
  )

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pr: 1,
        }}
      >
        <TitledSection title="Name">
          <LengthLimitedTextField
            label="Name"
            value={name}
            setValue={handleNameChanged}
            maxLength={64}
            required
            helperText={nameError}
          />
        </TitledSection>
        <SectionDivider />
        <TitledSection title="Description">
          <LengthLimitedTextField
            label="Introduction"
            value={introduction}
            setValue={handleIntroductionChanged}
            maxLength={300}
            multiline
            required
            helperText={introductionError}
          />
        </TitledSection>
        <SectionDivider />
        <TitledSection title="Compensation">
          <Grid container spacing={2} xs={12} sx={{ mt: 2 }}>
            <Grid xs={12} sm={4}>
              <TextField
                label="Signing Bonus"
                value={signingBonus}
                onChange={handleSigningBonusChanged}
                error={!!signingBonusError}
                helperText={signingBonusError}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <TextField
                label="Brokerage Commission Ratio"
                value={brokerageCommissionRatio}
                onChange={handleCommissionRatioChanged}
                error={!!brokerageCommissionRatioError}
                helperText={brokerageCommissionRatioError}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <TextField
                placeholder="Agent Commission Ratio"
                value={
                  brokerageCommissionRatio ? 100 - brokerageCommissionRatio : ""
                }
                disabled
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Tooltip
                        title="Updates depend on Brokerage Commission Ratio"
                        placement="left"
                      >
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{ color: "text.secondary2" }}
                        />
                      </Tooltip>
                      <Typography
                        variant="body2"
                        color="text.secondary2"
                        sx={{ ml: 2 }}
                      >
                        %
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </TitledSection>
        <SectionDivider />
        <TitledSection title="Fees">
          <Grid container spacing={2} xs={12} sx={{ mt: 2 }}>
            <Grid xs={12} sm={6}>
              <TextField
                label="Per Transaction Fee"
                value={perTransactionFee}
                onChange={(e) =>
                  handleChangedPositiveOnlyTextField(e, setPerTransactionFee)
                }
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="Monthly Desk Fee"
                value={monthlyDeskFee}
                onChange={(e) =>
                  handleChangedPositiveOnlyTextField(e, setMonthlyDeskFee)
                }
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </TitledSection>
        <SectionDivider />
        <TitledSection
          title={
            <>
              Perks{" "}
              <Typography variant="h6" component="span" color="text.secondary2">
                {selectedPerks.length}/8
              </Typography>
            </>
          }
        >
          <PerksEditor
            selectedPerks={selectedPerks}
            setSelectedPerks={setSelectedPerks}
          />
        </TitledSection>
        <SectionDivider />
        <TitledSection title="Note">
          <LengthLimitedTextField
            label="Note"
            maxLength={200}
            value={note}
            setValue={setNote}
            multiline
          />
        </TitledSection>
      </DialogContent>
      <LeftAlignedDialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendProposalButtonClicked}
          disabled={
            !!(
              nameError ||
              introductionError ||
              signingBonusError ||
              brokerageCommissionRatioError
            )
          }
        >
          Send proposal
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCancelButtonClicked}
        >
          Cancel
        </Button>
      </LeftAlignedDialogActions>
      <SendProposalDialog
        open={isSendProposalDialogOpen}
        onCancel={() => setIsSendProposalDialogOpen(false)}
        onSendProposal={handleSendProposalToCandidateButtonClicked}
        onSendingProposalFinished={handleSendingProposalFinished}
        proposalID={createdProposalID}
        isCreatingProposal={isSavingDraft}
        proposalIntroduction={introduction}
        proposalCommissionSplit={brokerageCommissionRatio}
        proposalSigningBonus={signingBonus}
        proposalPerks={selectedPerks}
        conversation={conversation}
      />
      <SaveDraftProposalDialog
        open={isSaveDraftDialogOpen}
        onCancel={handleSaveDraftCancelled}
        onSave={handleSaveDraftConfirmed}
        isSavingDraft={isSavingDraft}
      />
    </Dialog>
  )
}

function TitledSection({ title, children }) {
  return (
    <Column>
      <Typography variant="h6">{title}</Typography>
      {children}
    </Column>
  )
}

function SectionDivider() {
  return <Divider sx={{ my: 3 }} />
}
