import styled from "@emotion/styled"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

import Row from "./Row"

export default function SettingsSection({
  title,
  subtitle,
  subtitleButton,
  subtitleMaxWidth,
  children,
}) {
  return (
    <SettingsSectionContainer>
      <Typography variant="h6">{title}</Typography>
      <Row
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "text.secondary2", mt: 1, maxWidth: subtitleMaxWidth }}
        >
          {subtitle}
        </Typography>
        {subtitleButton}
      </Row>
      {children}
    </SettingsSectionContainer>
  )
}

export const SettingsSectionContainer = styled((props) => (
  <Paper elevation={0} {...props} />
))(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.only("xs")]: {
    padding: theme.spacing(2),
  },
  marginTop: theme.spacing(4),
  maxWidth: "100%",
}))
