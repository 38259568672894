import AllInclusiveIcon from "@mui/icons-material/AllInclusive"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Divider from "@mui/material/Divider"
import Fade from "@mui/material/Fade"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { alpha, styled, useTheme } from "@mui/material/styles"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Column from "../../../common/components/Column"
import Row, { RowButColumnOnMobile } from "../../../common/components/Row"
import { useIsMobile } from "../../../common/utils"
import {
  WhiteButton,
  WhiteHeader,
  WhiteOutlinedButton,
  WhiteSubtitle,
} from "../components/White"
import UpgradeProDialogBackgroundImage from "../resources/images/upgrade-pro-dialog-bg.png"
import {
  hideProDialog,
  selectBrokerages,
  selectIsForcedToPay,
  selectProDialog,
  showProDialogForBrokerageIndex,
} from "../slice"
import { FREE_PROSPECTS, LIGHT_WHITE, PLAN_DATA, PLAN_TYPES } from "../utils"
import BillingInfoDialog from "./BillingInfoDialog"
import BrokeragePaper from "./BrokeragePaper"

export default function UpgradeToProDialog() {
  const [selectedPlan, setSelectedPlan] = useState(PLAN_TYPES.annual)
  const selectedPlanData = PLAN_DATA[selectedPlan]
  const [isBillingInfoDialogOpen, setIsBillingInfoDialogOpen] = useState(false)
  const proDialogData = useSelector(selectProDialog)
  const brokerage = useSelector(selectBrokerages)[proDialogData.brokerageIndex]
  const isForcedToPay = useSelector(selectIsForcedToPay)
  const dispatch = useDispatch()

  const theme = useTheme()
  const isMobile = useIsMobile()

  function handleClosed() {
    dispatch(hideProDialog())
  }

  function handleUpgradeToProButtonClicked() {
    setIsBillingInfoDialogOpen(true)
    handleClosed()
  }

  function handleBillingInfoDialogClosed() {
    setIsBillingInfoDialogOpen(false)
  }

  function handleBillingInfoDialogBackButtonClicked() {
    setIsBillingInfoDialogOpen(false)
    dispatch(
      showProDialogForBrokerageIndex({
        index: proDialogData.brokerageIndex,
        source: "Back from billing info modal",
      })
    )
  }

  return (
    <Dialog
      open={proDialogData.open}
      onClose={handleClosed}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: `url(${UpgradeProDialogBackgroundImage})`,
          backgroundSize: "100% 100%",
          borderRadius: (theme) => theme.units.borderRadius,
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Column
          sx={{
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <WhiteHeader variant="h4">Upgrade to Pro</WhiteHeader>
          <WhiteSubtitle variant="body1" sx={{ mt: 1 }}>
            {proDialogData.newBrokerage
              ? `You're getting access to ${FREE_PROSPECTS} free trial agents. Unlock unlimited agents!`
              : "Access unlimited candidates and grow your brokerage faster."}
          </WhiteSubtitle>
          <BrokeragePaper brokerage={brokerage} />
          <Column gap={2} mt={4} alignItems="center">
            <NiceToggleButtonGroup
              value={selectedPlan}
              onChange={(e, newValue) =>
                newValue !== null && setSelectedPlan(newValue)
              }
              exclusive
              size="small"
            >
              <NiceToggleButton value={PLAN_TYPES.monthly}>
                Monthly
              </NiceToggleButton>
              <NiceToggleButton value={PLAN_TYPES.annual}>
                Annual
              </NiceToggleButton>
            </NiceToggleButtonGroup>
            <Box
              sx={{ position: "relative", width: "100%", alignSelf: "center" }}
            >
              <FadingPlanPrice
                planType={PLAN_TYPES.monthly}
                selectedPlan={selectedPlan}
                planData={selectedPlanData}
              />
              <FadingPlanPrice
                planType={PLAN_TYPES.annual}
                selectedPlan={selectedPlan}
                planData={selectedPlanData}
              />
            </Box>
            <RowButColumnOnMobile
              sx={{
                alignItems: "center",
              }}
            >
              <WhiteOutlinedButton
                variant="outlined"
                size="large"
                onClick={handleClosed}
                fullWidth
                sx={{ display: isForcedToPay ? "none" : "inline-flex" }}
              >
                Keep basic subscription
              </WhiteOutlinedButton>
              <WhiteButton
                variant="contained"
                size="large"
                sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
                onClick={handleUpgradeToProButtonClicked}
                fullWidth
              >
                Upgrade to Pro subscription
              </WhiteButton>
              <BillingInfoDialog
                open={isBillingInfoDialogOpen}
                onClose={handleBillingInfoDialogClosed}
                onBackButtonClicked={handleBillingInfoDialogBackButtonClicked}
                brokerage={brokerage}
                plan={selectedPlan}
              />
            </RowButColumnOnMobile>
          </Column>
        </Column>
        {isForcedToPay ? null : (
          <>
            <Divider sx={{ mt: 4, borderColor: "text.secondary2" }} />
            <RowButColumnOnMobile
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mt: 4,
                [theme.breakpoints.only("xs")]: {
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  "& .MuiBox-root:nth-of-type(n + 2)": {
                    mt: 2,
                  },
                },
              }}
            >
              <PlanHighlight
                title="Basic Subscription"
                subtitle={`${FREE_PROSPECTS} free trial prospects`}
                iconText={FREE_PROSPECTS}
              />
              <PlanHighlight
                title="Pro Subscription"
                subtitle="Unlimited prospects"
                iconText={<AllInclusiveIcon htmlColor="white" />}
              />
            </RowButColumnOnMobile>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

function FadingPlanPrice({ planType, selectedPlan, planData }) {
  const fadeIn = planType === selectedPlan
  return (
    <Fade in={fadeIn} style={{ display: fadeIn ? "block" : "none" }}>
      <Column
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <WhiteHeader variant="h2" fontWeight={500}>
          ${planData.price.toLocaleString()}
          <WhiteHeader component="span" fontSize="smaller">
            /{planData.period}
          </WhiteHeader>
        </WhiteHeader>
      </Column>
    </Fade>
  )
}

const NiceToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderColor: theme.palette.otherwise.secondaryWhite,
}))

const HEAVY_WHITE = alpha("#ffffff", 0.5)

const NiceToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.otherwise.secondaryWhite,
  width: "auto",
  padding: "4px 8px",
  "&:hover": {
    backgroundColor: LIGHT_WHITE,
  },
  "&.Mui-selected": {
    color: "white",
    backgroundColor: HEAVY_WHITE,
    "&:hover": {
      backgroundColor: HEAVY_WHITE,
    },
  },
}))

function PlanHighlight({ title, subtitle, iconText }) {
  return (
    <Row
      sx={{
        alignItems: "center",
        color: "white",
        gap: 2,
      }}
    >
      <Avatar
        sx={{
          backgroundColor: LIGHT_WHITE,
          fontWeight: 500,
          width: "48px",
          height: "48px",
        }}
      >
        {iconText}
      </Avatar>
      <Column>
        <WhiteHeader variant="overline" lineHeight={1}>
          {title}
        </WhiteHeader>
        <WhiteHeader variant="body1" sx={{ mt: 1 }}>
          {subtitle}
        </WhiteHeader>
      </Column>
    </Row>
  )
}
